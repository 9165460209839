body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gray-tag {
    background-color: darkgray;
    color: white;
}
.navbar .container {
    margin-left: inherit;
}

.p-datatable .p-datatable-tbody > tr:hover {
    background-color: rgba(227, 65, 65, 0.35) !important; /* Replace with your desired hover color */
}